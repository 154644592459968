var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-2 pt-4",
      staticStyle: { overflow: "hidden" },
      attrs: { link: _vm.link },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("v-progress-linear", {
        staticStyle: { "border-radius": "0" },
        attrs: {
          absolute: "",
          top: "",
          height: "10px",
          color: _vm.color,
          value: "100",
        },
      }),
      _c("div", [
        _c("h3", { staticClass: "subtitle-2 grey--text font-weight-regular" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("strong", { staticClass: "display-1 grey--text mb-1 d-block" }, [
          _vm._v(_vm._s(_vm.number)),
        ]),
        _c(
          "p",
          { staticClass: "subtitle-2 mb-1 grey--text font-weight-regular" },
          [_vm._v(_vm._s(_vm._f("currency")(_vm.value)))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }