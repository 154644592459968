<template>
  <v-card class="pa-2 pt-4" style="overflow: hidden" :link="link" @click="$emit('click')">
    <v-progress-linear
      absolute
      top
      height="10px"
      :color="color"
      value="100"
      style="border-radius: 0"
    ></v-progress-linear>
    <div>
      <h3 class="subtitle-2 grey--text font-weight-regular">{{title}}</h3>
      <strong class="display-1 grey--text mb-1 d-block">{{number}}</strong>
      <p class="subtitle-2 mb-1 grey--text font-weight-regular">{{value | currency}}</p>
    </div>
  </v-card>
</template>

<script>
import { defineStatusColor } from '@/utils/defineStatusColor';

export default {
  props: {
    link: {
      type: Boolean,
    },
    status: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    number: {
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    color() {
      return defineStatusColor(this.status);
    },
  },
};
</script>

<style>
</style>
