<template>
  <v-dialog
    no-click-animation
    v-model="value"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile v-if="contador">
      <the-nav-bar :title="contador.nome" @back="$router.go(-1)"></the-nav-bar>
      <v-container class="pa-5 pt-0">
      <v-row no-gutters v-if="loading">
            <v-col>
              <v-skeleton-loader type="list-item" :height="74" v-for="item in Array(4)" :key="item"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else-if="!dados.length">
            <v-col class="d-flex justify-center">
              <div class="ma-10 pa-10">Nenhum dado encontrado</div>
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col>
              <v-list two-line>
                <template v-for="(dado, index) in dados">
                  <v-list-item :key="dado.status">
                    <v-list-item-content>
                      <v-list-item-title>{{dado.nome}}</v-list-item-title>
                      <v-list-item-subtitle>{{dado.valor | currency}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-progress-circular
                        :rotate="270"
                        :size="50"
                        :width="4"
                        :value="(100 * dado.quantidade) / contador.quantidade"
                        :color="color"
                      >
                        <span class="mt-1">{{dado.quantidade}}</span>
                      </v-progress-circular>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="index + 1" v-if="index + 1 < dados.length"></v-divider>
                </template>
              </v-list>
            </v-col>
          </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TheNavBar from '@/components/TheNavBar.vue';
import propostaService from '@/services/propostaService';
import { defineStatusColor } from '@/utils/defineStatusColor';

export default {
  components: { TheNavBar },
  props: {
    contador: {
      required: true,
    },
    value: {
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dados: [],
    };
  },
  computed: {
    color() {
      return defineStatusColor(this.contador.status);
    },
  },
  watch: {
    contador: {
      handler(newValue) {
        if (!newValue) return;
        this.getContadorDetails();
      },
      deep: true,
    },
  },
  methods: {
    async getContadorDetails() {
      try {
        this.loading = true;
        const { data } = await propostaService.getContadorDetalhado({
          status: this.contador.status,
        });
        this.dados = data;
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
