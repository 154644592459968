<template>
  <v-list elevation="2" class="pa-0" style="overflow: hidden;">
    <v-list-item link>
      <v-list-item-icon>
        <v-icon>{{icon}}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{title}}</v-list-item-title>
        <v-list-item-subtitle class="caption">{{text}}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>
