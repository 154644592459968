var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _vm.contador
        ? _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("the-nav-bar", {
                attrs: { title: _vm.contador.nome },
                on: {
                  back: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              }),
              _c(
                "v-container",
                { staticClass: "pa-5 pt-0" },
                [
                  _vm.loading
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            _vm._l(Array(4), function (item) {
                              return _c("v-skeleton-loader", {
                                key: item,
                                attrs: { type: "list-item", height: 74 },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : !_vm.dados.length
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c("div", { staticClass: "ma-10 pa-10" }, [
                                _vm._v("Nenhum dado encontrado"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-list",
                                { attrs: { "two-line": "" } },
                                [
                                  _vm._l(_vm.dados, function (dado, index) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        { key: dado.status },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(dado.nome)),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      dado.valor
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c(
                                                "v-progress-circular",
                                                {
                                                  attrs: {
                                                    rotate: 270,
                                                    size: 50,
                                                    width: 4,
                                                    value:
                                                      (100 * dado.quantidade) /
                                                      _vm.contador.quantidade,
                                                    color: _vm.color,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mt-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(dado.quantidade)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      index + 1 < _vm.dados.length
                                        ? _c("v-divider", { key: index + 1 })
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }