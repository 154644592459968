var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "the-toolbar",
        { attrs: { title: "Visão Geral" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.getContadores } },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-2" },
        [
          _vm.loading
            ? _c(
                "v-row",
                _vm._l(Array(4), function (item) {
                  return _c(
                    "v-col",
                    {
                      key: item,
                      staticClass: "pa-2",
                      attrs: { cols: "6", md: "3" },
                    },
                    [
                      _c("v-skeleton-loader", {
                        attrs: { type: "image", height: 116 },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "v-row",
                _vm._l(_vm.contadores, function (contador) {
                  return _c(
                    "v-col",
                    {
                      key: contador.status,
                      staticClass: "pa-2",
                      attrs: { cols: "6", md: "3" },
                    },
                    [
                      _c("base-card-counter-color-status", {
                        attrs: {
                          status: contador.status,
                          title: contador.nome,
                          number: contador.quantidade,
                          value: contador.valor,
                          link: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.verDetalhes(contador)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "12", md: "4" } },
                [
                  _c("base-button-options", {
                    attrs: {
                      icon: "mdi-calendar-month",
                      title: "Vigência",
                      text: "Consulte as datas de vigência disponíveis",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "12", md: "4" } },
                [
                  _c("base-button-options", {
                    attrs: {
                      icon: "mdi-bell",
                      title: "Notificações",
                      text: "Não há notificações pendentes",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "12", md: "4" } },
                [
                  _c("base-button-options", {
                    attrs: {
                      icon: "mdi-help-circle",
                      title: "Ajuda",
                      text: "Em caso de dúvidas, consulte aqui",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-status", {
        attrs: { contador: _vm.statusSelected },
        on: {
          close: function ($event) {
            _vm.dialogStatus = false
          },
        },
        model: {
          value: _vm.dialogStatus,
          callback: function ($$v) {
            _vm.dialogStatus = $$v
          },
          expression: "dialogStatus",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }