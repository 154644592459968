<template>
  <div>
    <the-toolbar title="Visão Geral">
      <v-btn icon @click="getContadores">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </the-toolbar>

    <v-container class="pa-5 pt-2">
      <v-row v-if="loading">
        <v-col cols="6" md="3" class="pa-2" v-for="item in Array(4)" :key="item">
           <v-skeleton-loader type="image" :height="116"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="6" md="3" class="pa-2" v-for="contador in contadores" :key="contador.status">
           <base-card-counter-color-status
              :status="contador.status"
              :title="contador.nome"
              :number="contador.quantidade"
              :value="contador.valor"
              link
              @click="verDetalhes(contador)"
            ></base-card-counter-color-status>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pa-2">
          <base-button-options
            icon="mdi-calendar-month"
            title="Vigência"
            text="Consulte as datas de vigência disponíveis"
          ></base-button-options>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <base-button-options
            icon="mdi-bell"
            title="Notificações"
            text="Não há notificações pendentes"
          ></base-button-options>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <base-button-options
            icon="mdi-help-circle"
            title="Ajuda"
            text="Em caso de dúvidas, consulte aqui"
          ></base-button-options>
        </v-col>
      </v-row>
    </v-container>
    <dialog-status :contador="statusSelected" v-model="dialogStatus" @close="dialogStatus = false"></dialog-status>
  </div>
</template>

<script>
import propostaService from '@/services/propostaService';
import TheToolbar from '@/components/TheToolbar.vue';
import BaseButtonOptions from '@/components/BaseButtonOptions.vue';
import BaseCardCounterColorStatus from '@/components/BaseCardCounterColorStatus.vue';
import DialogStatus from './DialogStatus.vue';

export default {
  name: 'Dashboard',
  components: {
    DialogStatus, BaseCardCounterColorStatus, TheToolbar, BaseButtonOptions,
  },
  data() {
    return {
      loading: false,
      contadores: [],
      statusSelected: null,
      dialogStatus: false,
    };
  },
  methods: {
    verDetalhes(contador) {
      this.statusSelected = contador;
      this.dialogStatus = true;
    },
    async getContadores() {
      try {
        this.loading = true;
        const response = await Promise.all([
          propostaService.getContadorPorStatus({ status: '0' }),
          propostaService.getContadorPorStatus({ status: '1' }),
          propostaService.getContadorPorStatus({ status: '2' }),
          propostaService.getContadorPorStatus({ status: '3' }),
        ]);
        const contadores = response.map((contator) => contator.data);
        this.contadores = contadores;
      } catch (error) {
        this.$root.$snackBar.open(
          {
            color: 'error',
            message: 'Erro ao buscar dados, tente novamente mais tarde',
          },
        );
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getContadores();
  },
  beforeRouteLeave(to, from, next) {
    /**
     * verifica se existe algum modal aberto ao sair da página e fecha eles,
     *  assim a navegação fica mais natural ao voltar de página no celular ou no navegador
     * */
    if (this.dialogStatus) {
      this.dialogStatus = false;
      next(false);
      return;
    }
    next();
  },
};
</script>
