export const defineStatusColor = (statusNumber) => {
  let color = '#000';
  switch (+statusNumber) {
  case 0: {
    color = 'orange';
    break;
  }
  case 1: {
    color = 'blue';
    break;
  }
  case 2: {
    color = 'green';
    break;
  }
  case 3: {
    color = '#565656';
    break;
  }
  default: {
    break;
  }
  }
  return color;
};
